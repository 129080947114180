@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  overflow: hidden;
}

.rs-sidenav-default {
  height: 100%;
  /* overflow-y: auto; */
}

.userProfile .rs-btn.rs-btn-subtle {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.userProfile .rs-btn.rs-btn-subtle .rs-avatar {
  margin-right: 5px;
}

.sidebar {
  overflow: auto;
  max-width: 250px;
  min-width: 250px;
  min-height: calc(100vh - 122px);
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s;
  overflow: auto;
}

.custom-control {
  width: 100%;
  min-height: calc(100vh - 122px) !important;
  max-height: calc(100vh - 122px) !important;
  /* border: 1px solid green; */
  overflow-y: auto;
}

.tooggleNav button {
  width: 100%;
  background: transparent;
  text-align: left;
  padding: 15px 15px 5px 56px;
  font-size: 16px;
  line-height: 1.25;
  width: 100%;
  white-space: normal;
  color: #1675e0;
  position: relative;
  height: 50px;
}

.tooggleNav button:active,
.tooggleNav button:hover,
.tooggleNav button:focus {
  border: 0;
  outline: 0;
}

.tooggleNav {
  overflow: hidden;
}

.tooggleNav button svg {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 19px;
}

.mobileToggle {
  display: none;
}

.content-responsive {
  width: calc(100% - 250px);
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  right: 0;
}

.rs-sidenav-collapse-in {
  width: 250px !important;
  transition: all 0.3s linear !important;
}

.rs-sidenav {
  transition: all 0.3s linear !important;
}

.rs-modal {
  margin: 20px auto !important;
}

/* .rs-modal-full .rs-modal-dialog {
  width: 1120px;
  margin: auto;
} */

.rs-modal-full {
  overflow: auto;
}

/* Small devices (landscape phones, less than) */
@media (max-width: 991.98px) {
  .responsive-mobile {
    margin: 0px 10px !important;
  }

  .rs-modal-full .rs-modal-dialog {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media (max-width: 700px) {
  .responsive-mobile .h5 {
    font-size: 1rem;
  }
}

@media (max-width: 545.98px) {
  .mobile-align {
    flex-direction: column !important;
  }

  h3 {
    font-size: 20px !important;
  }

  .rs-modal-full {
    width: auto !important;
  }

  .rs-modal-full .rs-modal-dialog {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

}

@media (max-width: 630.98px) {

  /* .rs-modal-sm {
  width: auto!important;
} */
  .rs-modal-sm .rs-modal-dialog {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}

.rs-modal-wrapper .rs-modal-body {
  position: static;
}


@media (max-width: 415px) {

  .rs-modal-full .rs-modal-dialog {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .rs-modal-sm .rs-modal-dialog {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

.rs-modal-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width:768px) {
  .content-responsive-1 {
    width: 100% !important;
    margin-left: -194px;
    transition: all 0.3s linear;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {

  .sidebar {
    position: absolute;
    overflow: visible !important;
    top: 0px;
    width: 0px !important;
    min-width: 0px;
    max-width: 0px;
    /* min-width: 220px;
    max-width: 220px; */
    z-index: 2;
    min-height: calc(100vh - 53px);
    overflow: visible !important;
  }

  .rs-sidenav-default {
    height: 100%;
  }

  .tooggleNav button {
    height: 64px;
  }

  .tooggleNav button span {
    display: none;
  }

  .content-responsive {
    width: 100% !important;
  }

  .navbar-light .navbar-brand {
    /* margin-left:60px; */
    margin-left: 43px;
  }

  .sidebar .rs-sidenav {
    background: transparent;
  }

  .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle-caret,
  .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-menu-toggle>.rs-icon {
    animation: none !important;
    /* transition: transform 0.3s linear, -webkit-transform 0.3s linear; */
  }

  .rs-sidenav-collapse-out .rs-dropdown-toggle-caret {
    display: inline-block !important;
  }

  .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle-caret::before,
  .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-menu-toggle>.rs-icon::before {
    content: '\ea08' !important;
  }

  .sidebar .rs-sidenav .rs-nav {
    background: #f7f7fa;
    position: absolute;
    height: 100%;
    max-height: calc(100vh - 120px);
    width: 218px;
    transition: all 0.4s linear;
    overflow: auto;
  }

  .sidebar .rs-sidenav-collapse-in .rs-nav {
    margin-left: -218px;
    transition: all 0.3s linear !important;
    border: none;

  }

  .rs-sidenav-collapse-out .rs-dropdown {
    display: flex !important;
    flex: 1 1 auto !important;
    flex-direction: column-reverse !important;
  }

  .rs-sidenav .rs-dropdown .rs-dropdown-menu {
    -webkit-transition: height 0.2s ease-in !important;
    transition: height 0.2s ease-in !important;
  }

  .rs-dropdown-header {
    display: none !important;
  }

  .rs-sidenav-collapse-out .rs-dropdown>.rs-dropdown-menu {
    display: block !important;
    position: static !important;
    float: none !important;
    box-shadow: none;
    animation-name: none !important;
    padding: 0 !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    /* height: 0!important; */
  }

  .rs-dropdown-collapse>.rs-dropdown-menu {
    height: 0px !important;
    display: none;
    transition: height 0.2s ease-in !important;
  }

  .rs-dropdown-expand>.rs-dropdown-menu {
    display: block;
    height: auto !important;
    transition: height 0.2s ease-in !important;
  }

  .rs-dropdown .rs-dropdown-menu {
    z-index: 6 !important;

  }

  .rs-sidenav-collapse-out .rs-dropdown-menu {
    overflow: hidden !important;
  }

  .rs-sidenav-collapse-out .rs-dropdown-item>.rs-dropdown-item-content {
    padding: 11px 20px 11px 56px !important;
  }

  .rs-sidenav .rs-nav-item .rs-nav-item-content,
  .rs-sidenav .rs-dropdown .rs-dropdown-toggle {
    font-size: 16px;
    line-height: 1.25;
    padding-left: 56px;
    /* width: 100%; */
    white-space: normal;
  }

  .rs-sidenav .rs-dropdown .rs-dropdown-toggle {
    height: auto !important;
    padding-right: 40px !important;
  }

  .rs-dropdown.rs-dropdown-expand .rs-dropdown-toggle-caret {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .rs-dropdown .rs-dropdown-toggle-caret {
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  }

  .custom-control {
    width: 100%;
    /* min-height: calc(100vh - 110px) !important; */
  }
}

@media (max-width: 460px) {
  .navbar-brand img {
    width: 10rem !important;
  }

  .mobile-font {
    font-size: 20px !important;
  }

  .rs-avatar {
    width: 30px !important;
    height: 30px !important;
  }

  .rs-avatar>.rs-avatar-image {
    width: 30px !important;
    height: 30px !important;
  }
}

@media (max-width: 425px) {
  .navbar-brand img {
    width: 8rem !important;
  }

  .rs-btn h6 {
    font-size: 14px;
  }

  /* .tooggleNav button {
      height: 57px;
  } */
}

@media (max-width: 460.98px) {
  .tooggleNav button {
    height: 58px;
  }
}

@media (max-width: 424.98px) {
  .tooggleNav button {
    height: 54px;
  }
}

.discount {
  justify-content: space-around;
}

.discount-width {
  width: 110px;
}

.vh-120 {
  max-height: calc(100vh - 121px) !important;
  min-height: calc(100vh - 121px) !important;
}

.my-custom-scrollbar {
  /* position: relative; */
  max-height: calc(100vh - 248px);
  overflow: auto;
}